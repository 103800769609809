// .bx--form bx--form--fluid some-class{
//     margin-top: 30px;
// }


.iman{
    min-height: 100vh;
    background: url(../../imgs/fondologin.jpg) no-repeat fixed;
    background-size: cover;
    // padding-top: 21vh;
    // padding-left: 20%;
    // padding-right: 20%;
}