.train-day {
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    overflow-x: auto;
    background: #f4f4f4;
    
    .train-day-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
    }
    
    .train-day-header-field {
        font-size: large;
        font-weight: bold;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    .train-day-header-disponibility {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .train-day-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
    }
    
    .train-day-body-field {
        white-space: nowrap;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}