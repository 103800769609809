.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: white;
}

.popup-inner.close-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
}