.formLogin{
    background-color: #0082ce56;
}

// .bx--text-input{
//     background-color: #0082ce;
// }

.bx--label{
    color:black;
}