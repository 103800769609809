.user.bx--tile:hover{
    transform: scale(1.015);
}

.user.bx--tile{
    margin: 3%;
    min-height: 97%;
}

.user-name{
    color: #0082ce;
    margin: 1rem;
}

.user-bikes{
    font-style: italic;
}