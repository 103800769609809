.disponibility-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .disponibility-fields {
        display: flex;
        align-items: center;
    }

    .disponibility-field {
        margin-right: 10px;
    }

    .train-day-header-field {
        font-size: large;
        font-weight: bold;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
}