.img {
    margin-right: $ui-01;
}

.back {
    color: grayscale($color: #000000c7);
}

.bx--header {
    background-color: $ui-01;
    border-bottom: none;
}

.bx--header__menu-item:hover,
.bx--header__menu-trigger:hover,
.active a {
    background-color: $inverse-support-04 !important;
    color: white !important;
}

.bx--header__menu-item {
    color: $text-01 !important;
}

.bx--header__menu-trigger > svg {
    fill: $text-01 !important;
}

.bx--side-nav--expanded {
    width: 7rem;
    //height: 11rem!important;
}

