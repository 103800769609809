// .button{
//     margin: 10px;
// }

.bx--btn{
    background: #0082ce;
    margin-right: 2px;
    padding: 1%;
}

// .container {
//     position: fixed;
//     left: 350px;
//     padding: 0;
//     margin: 0;
//     background-color: #ff00ff00;
// }

// .left-element {
//     background: green;
//     display: inline-block;
//     float: left;
// }

// .right-element {
//     background: red;
//     display: inline-block;
//     float: left;
// }
