.popup {
    z-index: 1;
}

.button-days {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
}

.button-disabled {
    color: #9b9b9b;
}

.transition-right {
    color: #0082ce;
}
.transition-right:hover {
    transform: translateX(20%);
    transition-duration: 0.5s;
}
.transition-right:hover {
    transform: translateX(20%);
    transition-duration: 0.5s;
}

.transition-left {
    color: #0082ce;
}
.transition-left:hover {
    transform: translateX(-20%);
    transition-duration: 0.5s;
}
