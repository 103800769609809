.train-modal {
    text-align: start;

    .table-container {
        margin-top: 1rem;
        text-align: center;
    }

    .delete-friend-button{
        background-color: #F17979;
        color: black;
    }

    .add-friend-button{
        color: black;
        background-color: #A3F179;
    }


    .title-addfriend{
        margin-top: 2rem;
    }

    #select-friend{
        margin-bottom: 1rem;
    }

}
