@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';
@import './components/Header/header.scss';
@import './components/Login/Login.scss';
@import './pages/LandingPage/landing-page.scss';
@import './pages/EndpointsPage/endpoints-page.scss';
@import './pages/PilotosPage/_pilotos-page.scss';
@import './pages/CopilotosPage/_copilotos-page.scss';
@import './pages/CalendarioPage/_calendario-page.scss';
@import './pages/AyudaPage/_ayuda-page.scss';
@import '@carbon/grid/scss/grid';

.App {
  text-align: center;
}

.App-logo {
  width: 50px;  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}