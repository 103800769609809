.flex {
    display: flex;
    flex-direction: column;

    text-align: start;

    font-family: Arial, Helvetica, sans-serif;

    .flex-row {
        display: flex;

        h1 {
            background-color: rgba(0, 130, 206, 0.4);
            padding: 0.5rem;
            width: auto;
            font-weight: bold;
            font-size: 2rem;
        }

        p {
            text-align: start;
            margin-left: 0.5rem;
            align-self: center;
        }
    }

    table {
        td {
            height: 3rem;
            align-items: center;
        }

        img {
            height: 2rem;
        }
    }

    .margin {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    h4 {
        font-weight: bold;
        text-align: start;
        margin-bottom: 1rem;
    }

    .table-header {
        font-weight: bold;
    }

    .help-calendar-tile {
        display: inline-block;
        margin: 5px;
        box-shadow: 1px 1px 5px #0082ce;
        text-align: center;
    }
    .delete-friend-button{
        background-color: #F17979;
        color: black;
    }
    .add-friend-button{
        margin-top: 1rem;
        color: black;
        background-color: #A3F179;
    }
    .train-modal__checkboxes{
        display: flex;
    }
}