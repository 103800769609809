.extra-pilots-modal {
    text-align: start;

    .table-container {
        margin-top: 1rem;
        text-align: center;
    }

    .button-add-trainingPartner{
        background-color: rgb(91, 230, 91);
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .lista-bicicletas-disponibles{
        margin-bottom: 10px;
    }

}
