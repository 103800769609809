.user-profile {
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;

    h1 {
        color: #0082ce;
    }

    h4 {
        font-style: italic;
    }
}

.perfil-button-friends{
    margin-top: 1rem;
    border-radius: 1rem;
    padding: 1rem;
    width: auto;
    margin-bottom: 10px;
    background-color: darkgray;
}

.perfil-button-bikes{
    margin-top: 1rem;
    border-radius: 1rem;
    padding: 1rem;
    width: auto;
    margin-bottom: 10px;
    background-color: darkgray;
}