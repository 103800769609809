.calendar-tile{
    margin-top: 1rem;
    border:solid black 1px;
}

.button-div{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.calendar-button{
    justify-content: center;
    border-radius: 1rem;
    padding: 1rem;
}

.bikes-button{
    width: auto;
}

.day-name{
    color: #0082ce;
    font-size: 2.5rem;
}

.day-number{
font-style: italic;
font-size: 1.5rem;
}

.calendar-button-entreno{
    margin-top: 1rem;
    border-radius: 1rem;
    padding: 1rem;
    width: auto;
}