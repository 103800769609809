.train-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.train-title-name{
    margin-right: 1rem;
}

.turn-title{
    color: #0082ce;
    font-size: 1.2rem;
}

.calendar-button-pilotos-extra{
    background-color: #F63C3C;
    color: black;
    margin-top: 1rem;
    border-radius: 1rem;
    padding: 1rem;
    width: auto;
}