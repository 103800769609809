.train-modal {
    text-align: start;

    .table-container {
        margin-top: 1rem;
        text-align: center;
    }

    .train-modal__checkboxes {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
    }

}
