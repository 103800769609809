@import '@carbon/themes/scss/themes';
// Use the gray 10 theme

$carbon--theme: $carbon--theme--white;

@include carbon--theme();

@import 'carbon-components/scss/globals/scss/styles.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$feature-flags: (
  grid-columns-16: true,
);