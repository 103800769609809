.numberInput{
    color: black;
}

.bikes{
    margin: 10px;
    box-shadow: 1px 1px 5px #0082ce;
}

.icon-check{
    margin: 10px;
}

.bike-style{
    margin: 10px;
    border-radius: 1rem;
    padding: 15px;
    justify-content: center; 
    box-shadow: 1px 1px 5px #0082ce;
}

.bike-style-title{
    margin: 10px;
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    padding: 15px;
    position: relative;
    box-shadow: 1px 1px 5px #0082ce;
}

.bx--number {
    width: 11rem;
}